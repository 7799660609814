import './bootstrap';
import '../css/app.css';
import 'vue-final-modal/style.css';
import 'flag-icons/css/flag-icons.min.css';
import '../css/multiselect.css';
import 'vue-tel-input/vue-tel-input.css';
import '@vuepic/vue-datepicker/dist/main.css';

import VueTelInput from 'vue-tel-input';
import VueSocialSharing from 'vue-social-sharing';
import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import { createVfm } from 'vue-final-modal';
 import { i18nVue } from 'laravel-vue-i18n';
import { MotionPlugin } from '@vueuse/motion';
import MasonryWall from '@yeger/vue-masonry-wall';
import VueScrollTo from 'vue-scrollto';
import * as Sentry from '@sentry/vue';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import VueGtag from 'vue-gtag';

const appName = import.meta.env.VITE_APP_NAME || 'Gravity Academy';
const vfm = createVfm();

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob('./Pages/**/*.vue'),
        ),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            enabled: import.meta.env.VITE_APP_ENV === 'production',
            integrations: [Sentry.replayIntegration()],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
    });
        app.use(plugin)
        .use(ZiggyVue)
        .use(vfm)
        .use(MotionPlugin)
        .use(VueSocialSharing)
        .use(MasonryWall)
        .use(VueTelInput, {
            mode: 'auto',
        })
        .use(VueScrollTo)
        .use(i18nVue, {
            fallbackLang: 'en',
            resolve: async (lang) => {
                const langs = import.meta.glob('../../lang/*.json');
                return langs[`../../lang/php_${lang}.json`]();
            },
        })
        .use(FloatingVue)
        .use(VueGtag, {
            config: {
                enabled: import.meta.env.VITE_GOOGLE_ANALYTICS_ENABLED,
                id: import.meta.env.VITE_GOOGLE_MEASUREMENT_ID,
                appName: appName,
                params: {
                    anonymize_ip: false,
                },
            },
        })
        .mount(el);

        return app;
    },
    progress: {
        color: '#138381',
        // Whether to include the default NProgress styles...
        includeCSS: true,

        // Whether the NProgress spinner will be shown...
        showSpinner: true,
    },
});
